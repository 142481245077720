<template>
  <vs-card>
    <h6
      class=""
      style="color: rgb(184, 194, 204);"
    >
      {{ item.desc }}
    </h6>
    <vs-divider/>
    <vs-row
      vs-type="flex"
      vs-justify="space-between"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <h4>{{ item.name }}</h4>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <vs-switch
          v-model="item.value"
          @input="configUpdated"
          icon-pack="feather"
          vs-icon="icon-check"
        />
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  name: "ToggleConfig",
  methods: {
    configUpdated() {
      this.$emit('configUpdated', this.item);
    },
    successUpload(e) {
      console.log(e);
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
};
</script>

<style scoped>
.vs-card--content {
  margin: 0 !important;
}
</style>
